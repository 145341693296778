@import url("../../../../../node_modules/@fontsource/roboto/100.css");
@import url("../../../../../node_modules/@fontsource/roboto/300.css");
@import url("../../../../../node_modules/@fontsource/roboto/400.css");
@import url("../../../../../node_modules/@fontsource/roboto/500.css");
@import url("../../../../../node_modules/@fontsource/roboto/700.css");
@import url("../../../../../node_modules/@fontsource/roboto/900.css");

@import url("../../../../../node_modules/@fontsource/roboto/100-italic.css");
@import url("../../../../../node_modules/@fontsource/roboto/300-italic.css");
@import url("../../../../../node_modules/@fontsource/roboto/400-italic.css");
@import url("../../../../../node_modules/@fontsource/roboto/500-italic.css");
@import url("../../../../../node_modules/@fontsource/roboto/700-italic.css");
@import url("../../../../../node_modules/@fontsource/roboto/900-italic.css");

html :not(.fa):not(.main-header>.navbar>.sidebar-toggle):not(.glyphicon):not(.glyphicon-chevron-left):not(.glyphicon-chevron-right) {
    font-family: 'Roboto', sans-serif !important;
}
